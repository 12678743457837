import React, {Component} from "react";
import {
    ThemeProvider,
    createTheme,
    Arwes,
    Logo,
    Header,
    Words,
    Project,
    Code,
    Blockquote,
    SoundsProvider,
    createSounds,
    Puffs, Footer, Link
} from 'arwes';
const mySounds = {
    shared: { volume: 1, },  // Shared sound settings
    players: {  // The player settings
        typing: {
            sound: { src: ['/static/sound/typing.mp3'] },
            // settings: { oneAtATime: true }  // The custom app settings
        },
        deploy: {
            sound: { src: ['/static/sound/deploy.mp3'] },
            // settings: { oneAtATime: true }
        },
        click: {
            sound: { src: ['/static/sound/click.mp3'] },
            // settings: { oneAtATime: true }
        },
    }
};
export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state= {
            counts: 0
        }
        this.getcounts()
    }
    getcounts() {
        fetch('https://counts.42cloud.cn/api/counts.html')
            .then(response => response.json())
            .then(data => {
                this.setState({counts: data.Count})
            })
    }
    render() {
        return (
            <ThemeProvider theme={createTheme()}>
                <Arwes background='https://cdn.42cloud.cn/static/img/background.jpg' pattern='https://cdn.42cloud.cn/static/img/glow.png'>
                    <SoundsProvider sounds={createSounds(mySounds)}>
                        <Puffs>
                    <div style={{padding: 20}}>
                        <Header animate>

                            <h1 style={{margin: 0}}><Logo animate size={40}/> 42Counts 计数器</h1>
                        </Header>
                    </div>
                    <div style={{padding: 20}}>
                        <Project
                            animate
                            header='42Counts 计数器使用教程'
                        >
                            <Words animate >
                                1. 文章访问计数
                            </Words>
                            <Code animate type='pre' language='javascript'>{
                                `<span><a id="readcount"></a>次阅读</span>`
                            }</Code>
                            <Words animate >
                                2. 全局访问计数 （将下面代码插入footer）
                            </Words>
                            <Code animate type='pre' language='javascript'>{
                                `<p>本站总访问量：<a id="allcount"></a></p>`
                            }</Code>
                            <Words animate >
                                3. 将下面代码加入footer
                            </Words>
                            <Code animate type='pre' language='javascript'>{
                                `<script>
fetch('https://counts.42cloud.cn/api/counts.html')
      .then(response => response.json())
      .then(data => {
          if (document.getElementById("readcount")) {
              document.getElementById("readcount").innerHTML=data.Count;
          }
          if (document.getElementById("allcount")){
              document.getElementById("allcount").innerHTML=data.Allcount;
            }
      })
      .catch(err => console.error(err))
</script>`
                            }</Code>

                            <Blockquote data-layer='success' >大功告成</Blockquote>

                        </Project>
                    </div>
                        </Puffs>
                        <div style={{ padding: 20 }}>
                            <Footer animate>
                                <div style={{ textAlign: "center"}}>
                                    <Link href="https://www.beian.gov.cn/">
                                        <Words animate>津ICP备20000910号-1</Words>
                                    </Link>
                                    <span>&nbsp;|&nbsp;</span>
                                    <Link href="https://42cloud.cn/">
                                        <Words animate>Powered By 42Team</Words>
                                    </Link>
                                    <span>&nbsp;|&nbsp;</span>
                                    <span>本页面共&nbsp;{this.state.counts}&nbsp;次阅读</span>
                                </div>
                            </Footer>
                        </div>
                    </SoundsProvider>
                </Arwes>
            </ThemeProvider>

        )
    }
}
