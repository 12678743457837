import React from "react";
import Home from "../views/index/index"
import Test from "../views/test";
import {BrowserRouter, Route} from "react-router-dom";
const BasicRoute = () => (
    <BrowserRouter>

            <Route exact path="/" component={Home}/>
            <Route exact path="/test" component={Test}/>
    </BrowserRouter>
);


export default BasicRoute;
