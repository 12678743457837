import React, {Component} from "react";
import {
    createSounds,
    // Button,
    ThemeProvider,
    SoundsProvider,
    createTheme,
    Arwes,
    Footer,
    Words,
    Link
} from 'arwes';

// const loader = createLoader();
const mySounds = {
    shared: { volume: 1, },  // Shared sound settings
    players: {  // The player settings
        typing: {
            sound: { src: ['/static/sound/typing.mp3'] },
            settings: { oneAtATime: true }  // The custom app settings
        },
        deploy: {
            sound: { src: ['/static/sound/deploy.mp3'] },
            settings: { oneAtATime: true }
        },
        click: {
            sound: { src: ['/static/sound/click.mp3'] },
            // settings: { oneAtATime: true }
        },
    }
};
// const sounds = [
//     '/static/sound/ask.mp3',
//     '/static/sound/deploy.mp3'
// ]

export default class Test extends Component {
    render() {
        return (
            <ThemeProvider theme={createTheme()}>
                <SoundsProvider sounds={createSounds(mySounds)}>
                    <Arwes>
                        <div style={{ padding: 20 }}>
                            <Footer animate>
                                <div style={{ textAlign: "center"}}>
                                    <Link href="https://www.beian.gov.cn/">
                                        <Words animate>津ICP备20000910号-1</Words>
                                    </Link>
                                    <span>&nbsp;|&nbsp;</span>
                                    <Link href="https://42cloud.cn/">
                                        <Words animate>Powered By 42Team</Words>
                                    </Link>
                                </div>
                            </Footer>
                        </div>
                    </Arwes>
                </SoundsProvider>
            </ThemeProvider>
        )
    }
}
